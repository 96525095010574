.input-field {
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 80%;
}

.microsoft-login {
    width: 60%;
}

.side-logo {
    display: flex;
    margin-top: 4rem;
}

.side-logo > div {
    display: flex;
    height: 16rem;
    margin: 4rem 8rem;
}

.side-logo img {
    height: auto;
    object-fit: contain;
    width: 20rem;
}

.login-background {
    background-color: rgb(0 184 176 / 0.83);
    display: flex;
    min-height: 100vh;
}
