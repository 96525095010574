.uploaded-file-list {
    margin-left: 1rem;
}

.file-link {
    color: #1976d2;
}

.css-viou3o-MuiAutocomplete-root {
    width: 100%;
}

div[data-field="type"] .MuiDataGrid-cellContent {
    text-transform: capitalize;
}
