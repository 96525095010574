.users-title {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
}

.user-table {
    height: 75rem;
    width: 100%;
}

.dialog-title {
    min-width: 18.75rem;
}

.dialog-content {
    display: flex;
    flex-direction: column;
}
