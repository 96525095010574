/* General */
.tender-content {
    height: 58rem;
    padding: 1rem;
    width: 100%;
}

.date-form {
    margin-top: 1rem;
}

.date-form > div {
    align-items: center;
    justify-content: flex-end;
}

.date-type-selector {
    height: 3.5rem;
    text-align: left;
}

.date-tooltip {
    padding: 0;
}

.clear-dates {
    margin-top: 1rem;
    width: 12.5rem;
}

.bar-chart {
    margin: 1rem auto 2rem;
}

.bar-chart > svg {
    padding: 2rem;
}

.pie-chart {
    margin: 1rem auto 2rem;
}

/* Chart accompanyments */
.chart-legend {
    margin: 0;
    padding: 0;
    text-align: center;
}

.chart-legend-item {
    display: inline-block;
    margin-right: 1rem;
}

.chart-legend-icon {
    display: inline-block;
    height: 0.9rem;
    margin-right: 0.25rem;
    vertical-align: middle;
    width: 0.9rem;
}

.chart-tooltip {
    background-color: white;
    border-radius: 0.3rem;
    padding: 1rem;
}

.chart-pie-label-primary-title {
    font: bold 1.25rem sans-serif;
    text-decoration: underline;
}

.chart-pie-label-secondary-title {
    font: bold 1rem sans-serif;
}

.filter-clear {
    display: flex;
    justify-content: flex-end;
    margin: 0rem 1rem 1rem;
}

.date-rows {
 gap: 1rem;   
}

.date-box {
    width: 100%;
}
