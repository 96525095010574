@tailwind base;
@tailwind components;
@tailwind utilities;

@import './theme/common.css';
@import './theme/landing.css';
@import './theme/users.css';
@import './theme/tender-dashboard.css';
@import './theme/tender-detail.css';
@import './theme/authentication.css';
@import './theme/action.css';
