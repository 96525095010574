.text {
    --tw-text-opacity: 1;
    color: rgb(112 112 112 / var(--tw-text-opacity));
    font-family: Montserrat, sans-serif;
    font-weight: 100;
}

.text-padding {
    padding-left: 2rem;
    padding-right: 2rem;
}

.title {
    font-family: Montserrat, sans-serif;
    font-size: 1.7rem;
    font-weight: 700;
}

.secondary-title {
    font-family: Montserrat, sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}

.title-box {
    --tw-bg-opacity: 1;
    --tw-drop-shadow: drop-shadow(0 0.6rem 0.5rem rgb(0 0 0 / 0.04)) drop-shadow(0 0.25rem 0.03rem rgb(0 0 0 / 0.1));
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
    border-radius: 1rem;
    display: flex;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    flex-direction: column;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

.header-btn {
    display: flex;
    flex-direction: row;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

.header-btn > div {
    padding-left: 2rem;
    padding-right: 2rem;
}

.btn-primary {
    color: rgb(255 255 255);
    background-color: rgb(0 184 176) !important;
}

.btn-right {
    margin-left: 1rem;
}

.content {
    --tw-bg-opacity: 1;
    --tw-drop-shadow: drop-shadow(0 0.6rem 0.5rem rgb(0 0 0 / 0.04)) drop-shadow(0 0.25rem 0.03rem rgb(0 0 0 / 0.1));
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
    border-radius: 1rem;
    display: flex;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    flex-direction: column;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

.data-switch {
    box-shadow: none;
    float: right;
    margin: 0;
    min-width: 13.5rem;
    width: 100%;
}

.data-switch > div {
    align-items: center;
}

.data-switch.right-align > div{
    justify-content: flex-end;
}

.select {
    box-shadow: none;
    float: left;
    margin-right: 1rem;
    min-width: 13.5rem;
    width: 30%;
}

.tender-select {
    box-shadow: none;
    min-width: 13.5rem;
    width: 100%;
}

.form-label {
    box-shadow: none;
    float: left;
    text-align: left;
    width: 100%;
}

.dialog-label {
    box-shadow: none;
    text-align: left;
    width: 100%;
}

.date {
    box-shadow: none;
    float: left;
    text-align: left;
    width: 100%;
}

.date-input {
    height: 1.4375em;
    padding: 1rem 0.8rem;
}

.loading-linear {
    width: 100vw;
}

.loading-linear-container {
    align-items: center;
    display: flex;
    height: 6.25rem;
    justify-content: center;
    width: 100vw;
}

.hide {
    display: none;
}

.full-width {
    width: 100%;
}

.error {
    color: #d32f2f;
}

.stack-divider {
    flex: 10;
}

.action-table {
    width: 100%;
    margin-bottom: 1rem;
}

.action-table .actions {
    color: rgba(0, 0, 0, 0.6);
}

.action-table .textPrimary {
    color: rgba(0, 0, 0, 0.87);
}

.table-data {
    height: 58rem;
    padding: 1rem;
    width: 100%;
}

.action-actions {
    margin-bottom: 1rem;
    padding: 1rem;
}

.flash {
    width: 100%;
}


.fileupload-container {
    align-items: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 10px;
}

.filesRequiredList {
    padding: 20px;
}

.dropzone {
    background-color: #E7EFFB;
    border-color: #B0BCD6;
    border-radius: 10px;
    border-style: dashed;
    color: #3F3F3F;
    cursor: pointer;
    padding: 20px;
    text-align: center;
}

.fileupload {
    margin: 10px;
}

.fileinformation {
    text-align: center;
}

.file-list {
    color: gray;
}

.file-list h4 {
    color: black;
}

.removeButton {
    background: transparent linear-gradient(180deg, #F7F7FD 0%, #F9F9FE 100%) 0% 0% no-repeat padding-box;
    border: none;
    color: red;
    cursor: pointer;
    font-weight: 600;
    margin-left: 10px;
}

.removeButton:hover {
    background: red;
    color: white;
}

.footer {
    display: flex;
    height: 3.5rem;
    justify-content: flex-end;
    padding: 1rem;
    width: 100%;
}

.logout {
    color: rgb(0 184 176);
    font-style: italic;
    text-decoration: underline;
}
