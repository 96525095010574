.field-grid {
    align-content: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 1rem;
    padding: 0 2rem;
}

.step-form {
    align-content: center;
    margin: 0 10%;
}

.form-item {
    align-items: center;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    float: left;
    padding: 0;
    width: 100%;
}

.summary {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
}

.summary-fields {
    display: flex;
    flex-direction: row;
    height: auto;
    margin-left: 2rem;
    margin-top: 1rem;
}

.summary-fields > div {
    margin-right: 1rem;
    min-width: 15.6rem;
}

.summary-fields > div:last-child {
    margin-right: 0;
}

.summary-score {
    margin-left: 2rem;
}

.summary-score-blurb {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0.5rem;
}

.summary-score-left {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
}

.summary-score-right {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.step-status {
    margin-left: -1rem;
}

.step-status--startup {
    margin-bottom: 1rem;
    height: 5rem;
}

.status-label {
    float: right;
    width: 25%;
}

.step-submission {
    display: flex;
    justify-content: center;
    width: 100%;
}

.step-submission > button {
    width: 8rem;
}

.review-switch {
    float: right;
}

.form-group {
    box-shadow: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    float: left;
    margin: 0;
    padding: 0 1rem;
    width: 100%;
}

.form-group > div {
    padding: 1rem;
    width: 33%;
}

.form-group .two-columns {
    width: 50%;
}


.form-group-label {
    box-shadow: none;
    float: left;
    font-size: 1.1rem;
    text-align: left;
    width: 100%;
}

.group-field {
    flex-basis: 100%;
    max-width: 100%;
    width: 6.25rem;
}

.feedback-dialog {
    min-width: 37.5rem;
}

.no-feedback {
    align-items: center;
    display: flex;
    height: 6.25rem;
    justify-content: center;
}

.feedback-avatar {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: .5rem;
}

.feedback-user {
    font-size: xx-small;
}

.feedback-avatar > div {
    height: 3.75rem;
    width: 3.75rem;
}

.btn-update {
    height: 3.5rem;
    margin-top: auto;
}

.tender-loader {
    align-items: center;
    display: flex;
    height: 75vh;
    justify-content: center;
    width: 100%;
}

.tab-panel {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    z-index: 0;
}

.tab-panel > div {
    width: 100%;
}

.selected-submission {
    width: 100%;
}

.tender-box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tender-details {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 5rem 1.5rem 1.5rem;
}

.tender-title {
    margin: 0.25rem;
}

.review-button {
    margin-top: 1rem;
}

.text-field {
    padding-right: 1rem;
}

.single-column {
    flex: 1;
    max-width: 100%;
    width: 100%;
}
