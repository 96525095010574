.dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* Welcome Card */
.welcome-box {
    --tw-bg-opacity: 1;
    --tw-drop-shadow: drop-shadow(0 0.6rem 0.5rem rgb(0 0 0 / 0.04)) drop-shadow(0 0.25rem 0.03rem rgb(0 0 0 / 0.1));
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
    border-radius: 1rem;
    display: flex;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    flex-direction: column;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

.welcome-title {
    margin-top: 1rem;
}

.welcome-desc {
    font-size: 0.8rem;
    font-weight: 100;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
}

.welcome-help {
    font-size: 0.7rem;
    margin-bottom: 0.75rem;
    padding-top: 0.5rem;
}

/* Category Card */
.card-base {
    border-radius: 0.75rem;
    margin: 0.5rem;
    width: 24rem;
}

.card-image {
    background-position-x: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 38.125rem;
    justify-content: center;
    padding-top: 24rem;
}

.card-image div {
    align-items: center;
    background: linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0)) 0% 0%;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.card-title {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    padding-bottom: 0.5rem;
} 
